import { Box, Typography } from "@mui/material"
import Footer from "../components/Footer"
import Header from "../components/Header"

const RefundPolicy = () => {
    return (
        <>
            <Header />
            <Box sx={{ padding: 4, marginVertical: 4 }}>
                <Typography align="center" variant="h5" sx={{ marginBottom: 4 }}>
                    Refund and Cancellation policy
                </Typography>
                <Typography sx={{ marginBottom: 2 }}>
                    This refund and cancellation policy outlines how you can cancel or seek a refund for a product / service
                    that you have purchased through the Platform. Under this policy:
                </Typography>
                <ol>
                    <li>
                        <Typography sx={{ marginBottom: 2 }}>Cancellations will only be considered if the request is made 7 days
                            of placing the order. However,
                            cancellation requests may not be entertained if the orders have been communicated to such sellers /
                            merchant(s) listed on the Platform and they have initiated the process of shipping them, or the
                            product is out for delivery. In such an event, you may choose to reject the product at the doorstep.</Typography>
                    </li>
                    <li>
                        <Typography sx={{ marginBottom: 2 }}>Supashvanathacapitalpvtltd does not accept cancellation requests for perishable items like
                            flowers, eatables, etc. However, the refund / replacement can be made if the user establishes that
                            the quality of the product delivered is not good.</Typography>
                    </li>
                    <li>
                        <Typography sx={{ marginBottom: 2 }}>In case of receipt of damaged or defective items, please report to our customer service team. The
                            request would be entertained once the seller/ merchant listed on the Platform, has checked and
                            determined the same at its own end. This should be reported within 7 days
                            of receipt of products.
                            In case you feel that the product received is not as shown on the site or as per your expectations,
                            you must bring it to the notice of our customer service within 7 days
                            of receiving the product. The
                            customer service team after looking into your complaint will take an appropriate decision.</Typography>
                    </li>
                    <li>
                        <Typography sx={{ marginBottom: 2 }}>In case of complaints regarding the products that come with a warranty from the manufacturers,
                            please refer the issue to them.</Typography>
                    </li>
                    <li>
                        <Typography sx={{ marginBottom: 2 }}>In case of any refunds approved by Supashvanathacapitalpvtltd 10 days
                            , it will take for the
                            refund to be processed to you.</Typography>
                    </li>
                </ol>

            </Box >
            <Footer />
        </>
    )
}

export default RefundPolicy