import { Box, Typography } from "@mui/material"
import Footer from "../components/Footer"
import Header from "../components/Header"

const ReturnPolicy = () => {
    return (
        <>
            <Header />
            <Box sx={{ padding: 4, marginVertical: 4 }}>
                <Typography align="center" variant="h5" sx={{ marginBottom: 4 }}>
                    Return Policy
                </Typography>
                <Typography sx={{ marginBottom: 2 }}>
                    We offer refund / exchange within first 7 days 7 days
                    from the date of your purchase. If have passed
                    since your purchase, you will not be offered a return, exchange or refund of any kind. In order to become
                    eligible for a return or an exchange, (i) the purchased item should be unused and in the same condition as
                    you received it, (ii) the item must have original packaging, (iii) if the item that you purchased on a sale,
                    then the item may not be eligible for a return / exchange. Further, only such items are replaced by us
                    (based on an exchange request), if such items are found defective or damaged.
                </Typography>
                <Typography sx={{ marginBottom: 2 }}>
                    You agree that there may be a certain category of products / items that are exempted from returns or
                    refunds. Such categories of the products would be identified to you at the item of purchase. For exchange
                    / return accepted request(s) (as applicable), once your returned product / item is received and inspected
                    by us, we will send you an email to notify you about receipt of the returned / exchanged product. Further.
                    If the same has been approved after the quality check at our end, your request (i.e. return / exchange) will
                    be processed in accordance with our policies.
                </Typography>


            </Box >
            <Footer />
        </>
    )
}

export default ReturnPolicy