import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutsUs";
import Terms from "../pages/Terms";
import Privacy from "../pages/Privacy";
import RefundPolicy from "../pages/RefundPolicy";
import ReturnPolicy from "../pages/ReturnPolicy";
import ShippingPolicy from "../pages/ShippingPolicy";

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/refund-policy" element={<RefundPolicy />} />
                <Route path="/return-policy" element={<ReturnPolicy />} />
                <Route path="/shipping-policy" element={<ShippingPolicy />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter
