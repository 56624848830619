import { Box, Grid2, Typography, Paper, Link } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

const Footer = () => {
    const navigate = useNavigate();
    const handleNav = (screen) => {
        navigate(screen)
    }
    return (
        <Paper elevation={2} sx={{ px: 4, py: 2 }}>
            <Grid2 container>
                <Grid2 size={{ xs: 12, sm: 6, md: 3, lg: 3 }} sx={{my:2}}>
                    <Grid2 container>
                        <Grid2 size={{}}>
                            <img src='/images/logo.png' width={64} height={46} />
                        </Grid2>
                        <Grid2 size={{}} className='hvCenter'>
                            <Typography variant='h5'>BELLS</Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Typography variant='caption'>
                                {"© 2024, SUPARSHVANATHA CAPITAL PRIVATE LIMITED."}
                            </Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Typography variant='caption'>
                                All rights reserved.
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3, lg: 3 }} sx={{my:2}}>
                    <Grid2 container>
                        <Grid2 size={12}>
                            <Typography variant='h6'>COMPANY</Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Typography sx={{cursor:'pointer'}} onClick={() => (handleNav('/aboutus'))} variant='caption'>About us</Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Typography variant='caption'>Careers</Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Typography variant='caption'>Team</Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3, lg: 3 }} sx={{my:2}}>
                    <Grid2 container>
                        <Grid2 size={12}>
                            <Typography variant='h6'>SUPPORT</Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Typography variant='caption'>Contact uss</Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Typography variant='caption'>List of charges</Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Typography variant='caption'>Chat</Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Grid2 size={{ xs: 12, sm: 6, md: 3, lg: 3 }} sx={{my:2}}>
                    <Grid2 container>
                        <Grid2 size={12}>
                            <Typography variant='h6'>LEGAL</Typography>
                        </Grid2>
                        <Grid2 size={12}>
                            <Link color='#000' sx={{textDecoration:'none'}} href="/terms" variant='caption'>Terms & conditions</Link>
                        </Grid2>
                        <Grid2 size={12}>
                            <Link color='#000' sx={{textDecoration:'none'}} href="/privacy-policy" variant='caption'>Privacy policy</Link>
                        </Grid2>
                        <Grid2 size={12}>
                            <Link color='#000' sx={{textDecoration:'none'}}  href="/refund-policy" variant='caption'>Refund policy</Link>
                        </Grid2>
                        <Grid2 size={12}>
                            <Link color='#000' sx={{textDecoration:'none'}}  href="/return-policy" variant='caption'>Return policy</Link>
                        </Grid2>
                        <Grid2 size={12}>
                            <Link color='#000' sx={{textDecoration:'none'}}  href="/shipping-policy" variant='caption'>Shipping policy</Link>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Paper>
    )
}

export default Footer;
